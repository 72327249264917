import React from 'react'
import Layout from '../components/layout'

import Banner from '../components/Banner'

import { graphql } from 'gatsby'
import Hero from '../components/Hero'

export default ({ data }) => (
  <Layout>
    <Hero big="true" gradient="true" img={data.homeImage.childImageSharp.fluid}>
      <Banner
        title="Godfrey Creative"
        info="Web Video Email"
        info2="{Digital Communications}"
      />
    </Hero>
  </Layout>
)

export const query = graphql`
  query {
    homeImage: file(relativePath: { eq: "defaultBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
