import React from 'react'
import styles from './css/banner.module.css'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    fontSize: '.8rem',
    textTransform: 'uppercase',
  },
  info2: {
    color: '#7fdbdb',
  },
}))

const Banner = ({ pre, title, info, children, info2 }) => {
  const classes = useStyles()
  return (
    <div className={styles.banner}>
      <Container maxWidth="md">
        <h2>{pre}</h2>
        <h1>{title}</h1>
        <p className={classes.text}>
          {info} <span className={classes.info2}>{info2}</span>
        </p>
        {children}
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          Forging Creative Digital Communications
          <div className={classes.grow} />
          <Button
            className={classes.button}
            component={Link}
            to="/about"
            color="secondary"
          >
            About
          </Button>
          <Button
            component="a"
            href="mailto:info@godfreycreative.io"
            className={classes.button}
            color="secondary"
          >
            Contact
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Banner
